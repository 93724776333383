export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';
export const LIGHTBOX_CHANGE = 'LIGHTBOX_CHANGE';
export const LIGHTBOX_NEXT = 'LIGHTBOX_NEXT';
export const LIGHTBOX_PREV = 'LIGHTBOX_PREV';
export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX';


export const closeLightbox = () => ({ type: CLOSE_LIGHTBOX });
export const lightboxChange = ({ index }) => ({ type: LIGHTBOX_CHANGE, index });
export const lightboxNext = () => ({ type: LIGHTBOX_NEXT });
export const lightboxPrev = () => ({ type: LIGHTBOX_PREV });
export const openLightbox = ({ photoIndex, urls }) => ({ type: OPEN_LIGHTBOX, photoIndex, urls });
